import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import {get} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";

const dayjs = require('dayjs');

const dateFormat = 'DD.MM.YYYY';

class PartnerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: [],
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    async loadData() {
        const [res, err] = await get(this, "/partner");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({partners: res.data});
    }

    async componentDidMount() {
        await this.loadData();
    }

    render() {
        function periodDateFormatter(date) {
            return dayjs(new Date(date.substr(0, 10))).format(dateFormat);
        }

        return <Layout activeMenu="partner">
            <h1>Partners ({this.state.partners ? this.state.partners.length : 0})</h1>
            <br/>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}

            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>Nr.</th>
                    <th>Name</th>
                    <th>Created</th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {this.state.partners.map((value, index) => {
                    let status = <span className="badge badge-pill badge-warning">{value.status}</span>;
                    if (value.status === "active") {
                        status = <span className="badge badge-pill badge-success">{value.status}</span>;
                    }

                    return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value.name}</td>
                        <td>{periodDateFormatter(value.created)}</td>
                        <td>{status}</td>
                        <td>
                            <Button variant="outline-primary" href={"/partner/" + value.partnerId + "/view"}>View</Button>
                            <Button variant="outline-primary" href={"/partner/" + value.partnerId + "/edit"}>Edit</Button>
                        </td>
                    </tr>
                })}
                </tbody>
            </Table>
        </Layout>;
    }
}

export default PartnerList