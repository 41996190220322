import React from 'react'
import Alert from 'react-bootstrap/Alert';
import {get} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
import StorageNav from "../../component/StorageNav";
import filesize from "filesize";
import {ProgressBar, Spinner} from "react-bootstrap";
import LocationSelector from "../../component/LocationSelector";
class StorageOverview extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            loaded: false,
            location: location,
            nodes: [],
            containers: [],
            statistics: {
                DataSizeMax: 0,
                DataSizeFree: 0,
                DataSizePaid: 0,
            },
            errorMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }
    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/storage/overview/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        this.setState({errorMessage: ""});
        this.setState({loaded: true});
        this.setState({nodes: res.data.Nodes});
        this.setState({containers: res.data.Containers});
        this.setState({statistics: res.data.Statistics});
    }

    async componentDidMount() {
        await this.loadData();
    }
    render() {
        return <Layout activeMenu="storage">
            <StorageNav activeKey="overview"/>
            <h1>Storage statistics</h1>
            <LocationSelector></LocationSelector>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            {!this.state.loaded &&
                <>
                    <Spinner animation="border" variant="primary"/> Data not loaded...
                    <br/><br/>
                </>
            }
            {this.state.loaded && <>
            <Table bordered hover>
                <tbody>
                <tr>
                    <td width="33%">Data: used / total</td>
                    <td width="33%">{filesize(this.state.statistics.DataSizeMax - this.state.statistics.DataSizeFree)} / {filesize(this.state.statistics.DataSizeMax)} ({100 - this.state.statistics.DataSizeFreePerc}%)</td>
                    <td width="33%"><ProgressBar now={100 - this.state.statistics.DataSizeFreePerc} max={100}/></td>
                </tr>
                <tr>
                    <td width="33%">Paid data</td>
                    <td width="33%">{filesize(this.state.statistics.DataSizePaid)} ({this.state.statistics.DataSizePaidPerc}%, {Math.round((this.state.statistics.DataSizePaid / (1024 * 1024 * 1024 * 1024)) * 6)} &euro;/month)</td>
                    <td width="33%"><ProgressBar now={this.state.statistics.DataSizePaidPerc} max={100}/></td>
                </tr>
                <tr>
                    <td>Num nodes</td>
                    <td>{this.state.statistics.NumNodes}</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>Nodes with service <strong>StorageDrive</strong> online</td>
                    <td>{this.state.statistics.NumNodesWithServiceStorageDriveOnline} ({this.state.statistics.NumNodesWithServiceStorageDriveOnlinePerc}%)</td>
                    <td><ProgressBar now={this.state.statistics.NumNodesWithServiceStorageDriveOnline} max={this.state.statistics.NumNodes}/></td>
                </tr>
                <tr>
                    <td>Containers</td>
                    <td>{this.state.statistics.NumContainers}</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>Containers with <strong>WriteActive</strong></td>
                    <td>{this.state.statistics.NumContainersWriteActive} ({this.state.statistics.NumContainersWriteActivePerc}%)</td>
                    <td><ProgressBar now={this.state.statistics.NumContainersWriteActive} max={this.state.statistics.NumContainers}/></td>
                </tr>
                <tr>
                    <td>Containers with <strong>KeepEmpty</strong></td>
                    <td>{this.state.statistics.NumContainersKeepEmpty} ({this.state.statistics.NumContainersKeepEmptyPerc}%)</td>
                    <td><ProgressBar now={this.state.statistics.NumContainersKeepEmpty} max={this.state.statistics.NumContainers}/></td>
                </tr>
                <tr>
                    <td>Drives: online / total</td>
                    <td>{this.state.statistics.NumDrivesOnline} / {this.state.statistics.NumDrives} ({this.state.statistics.NumDrivesOnlinePerc}%)</td>
                    <td><ProgressBar now={this.state.statistics.NumDrivesOnline} max={this.state.statistics.NumDrives}/></td>
                </tr>
                <tr>
                    <td>Drives: parity / data</td>
                    <td>{this.state.statistics.NumParityDrives} / {this.state.statistics.NumDataDrives} ({this.state.statistics.NumParityDrivesPerc}% / {this.state.statistics.NumDataDrivesPerc}%)</td>
                    <td><ProgressBar now={this.state.statistics.NumParityDrives} max={this.state.statistics.NumDrives}/></td>
                </tr>
                </tbody>
            </Table>
            <h1>Storage overview</h1>
            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>&nbsp;</th>
                    {this.state.nodes.map((node, index) => {
                        return <th>
                            {node.Services.StorageDrive.Online &&
                            <span className="badge badge-pill badge-success">Drive</span>}
                            {!node.Services.StorageDrive.Online &&
                            <span className="badge badge-pill badge-warning">Drive</span>}

                            <a href={"/storage/node/" + node.NodeId + "/view?location=" + this.state.location}>{node.Uuid}</a>
                        </th>
                    })}
                </tr>
                </thead>
                <tbody>
                {this.state.containers.map((container, index) => {
                    return <tr>
                        <td>
                            {container.ContainerId === "000000000000000000000000" &&
                            <div>Drives not added to container</div>
                            }
                            {container.ContainerId !== "000000000000000000000000" &&
                            <div>
                                <div>
                                    <a href={"/storage/container/" + container.ContainerId + "/view?location=" + this.state.location}>{container.ContainerId}</a>
                                </div>
                                <div>
                                    {container.WriteActive && <span className="badge badge-pill badge-success">WriteActive</span>}
                                    {!container.WriteActive && <span className="badge badge-pill badge-warning">NOT WriteActive</span>}
                                    {container.KeepEmpty && <span className="badge badge-pill badge-warning">KeepEmpty</span>}
                                    {!container.KeepEmpty && <span className="badge badge-pill badge-success">NOT KeepEmpty</span>}
                                </div>
                                <div>Size {filesize(container.Size)}</div>
                                <div>Free {filesize(container.Free)}</div>
                                <div>Usage {filesize(container.Size - container.Free)} ({100 - container.FreePercentage}%)</div>
                                <div><ProgressBar now={100 - container.FreePercentage} max={100}/></div>
                            </div>
                            }
                        </td>
                        {container.DrivesByNodes && container.DrivesByNodes.map((nodeDrives, index) => {
                            return <td>
                                    {nodeDrives && nodeDrives.map((drive, index) => {
                                        return <div>
                                            {drive.Online && <span className="badge badge-pill badge-success">Online</span>}
                                            {!drive.Online && <span className="badge badge-pill badge-warning">Offline</span>}
                                            <a href={"/storage/drive/" + drive.DriveId + "/view?location=" + this.state.location}>{drive.Uuid}</a>
                                        </div>
                                    })}
                            </td>
                        })}
                    </tr>
                })}
                </tbody>
            </Table>
            </>
            }
        </Layout>;
    }
}

export default StorageOverview