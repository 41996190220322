import React from 'react'
import Layout from "../component/Layout";
import AccountNav from "../component/AccountNav";
import {get, post} from '../utils/request'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Alert, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import LocationSelector from "../component/LocationSelector";

const filesize = require('filesize');

const dateFormat = 'YYYY-MM-DD';
const requestDateFormat = 'YYYY-MM-DD';
const chartToolTipFormat = 'YYYY.MM.DD-H';

const dayjs = require('dayjs');

const divStyle = {
    margin: '40px auto',
    width: '600px'
};

class Usage extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            id: this.props.match.params.id,
            loaded: false,
            location: location,
            startDate: new Date(dayjs().subtract(1, 'months').format(dateFormat)),
            endDate: new Date(dayjs().format(dateFormat)),
            storageUsage: {
                Records: [],
                Average: 0
            },
            downloadUsage:{
                Records: [],
                Average: 0
            },
            errorMessage: ""
        };
        this.loadStorageUsageHistory = this.loadStorageUsageHistory.bind(this);
        this.loadDownloadUsageHistory = this.loadDownloadUsageHistory.bind(this);
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    }

    async loadStorageUsageHistory() {
        if(!this.state.location) {
            return;
        }

        let path = "/account/userUsageHistory/" + this.state.id + "/in/" + this.state.location;
        const data = {
            startDate: dayjs(this.state.startDate).format(requestDateFormat),
            endDate: dayjs(this.state.endDate).format(requestDateFormat),
        };
        const [res, err] = await post(this, path, data);
        if (err) {
            if (err.response && err.response.status && err.response.status === 404) {
                this.setState({errorMessage: "Data not available at this moment"});
            } else {
                this.setState({errorMessage: "error: " + err});
            }
            return
        }
        this.setState({loaded: true});
        this.setState({storageUsage: res.data})
    }

    async loadDownloadUsageHistory() {
        if(!this.state.location) {
            return;
        }

        let path = "/storage/downloadHistory/" + this.state.id + "/from/" + dayjs(this.state.startDate).format(requestDateFormat) + "/to/" + dayjs(this.state.endDate).format(requestDateFormat) + "/in/" + this.state.location;
        const [res, err] = await get(this, path);
        if (err) {
            if (err.response && err.response.status && err.response.status === 404) {
                this.setState({errorMessage: "Data not available at this moment, user sync not finished?"});
            } else {
                this.setState({errorMessage: "error: " + err});
            }
            return
        }
        this.setState({loaded: true});
        this.setState({downloadUsage: res.data})
    }

    async componentDidMount() {
        await this.loadDownloadUsageHistory();
        await this.loadStorageUsageHistory();
    }

    async handleChangeStartDate(date) {
        await this.setState({startDate: date})
        await this.loadStorageUsageHistory();
        await this.loadDownloadUsageHistory();
    }

    async handleChangeEndDate(date) {
        await this.setState({endDate: date})
        await this.loadStorageUsageHistory();
        await this.loadDownloadUsageHistory();
    }

    render() {
        function formatFunction(bytes) {
            return filesize(bytes);
        }

        function usageToolTipFormatter(bytes) {
            return ["Usage: " + formatFunction(bytes) + " (" + bytes + " B)"];
        }

        function downloadedToolTipFormatter(bytes) {
            return ["Usage: " + formatFunction(bytes) + " (" + bytes + " B)"];
        }

        function dateFormatter(date) {
            return dayjs(new Date(date.substr(0, 10))).format('MM-DD');
        }

        let storageChartData = [];
        let formattedPeriod = "";

        let pushed = new Set();
        this.state.storageUsage.Records.forEach((item) => {
            formattedPeriod = dayjs(new Date(item.Period.substr(0, 10) + " " + item.Period.substr(11, 2) + ":00")).format(chartToolTipFormat);

            if (!pushed.has(formattedPeriod)) {
                pushed.add(formattedPeriod);
                storageChartData.push({name: formattedPeriod, usage: item.Usage});
            }
        });

        let downloadChartData = [];
        pushed = new Set();
        this.state.downloadUsage.Records.forEach((item) => {
            formattedPeriod = dayjs(new Date(item.Period.substr(0, 10) + " " + item.Period.substr(11, 2) + ":00")).format(chartToolTipFormat);

            if (!pushed.has(formattedPeriod)) {
                pushed.add(formattedPeriod);
                downloadChartData.push({name: formattedPeriod, usage: item.Downloaded});
            }
        });

        return <Layout activeMenu="account">
            <AccountNav activeKey="usage" id={this.state.id}/>
            <div style={divStyle} className="usage-ctr">
                <h1>Usage history</h1>
                <br/>
                {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
                <LocationSelector></LocationSelector>
                <br/>
                {!this.state.loaded &&
                    <>
                        <Spinner animation="border" variant="primary"/> Data not loaded...
                        <br/><br/>
                    </>
                }
                {this.state.loaded && <>
                <Container className="usage-ctr">
                    <Row>
                        <Col className="col-sm-2">
                            <p>Start date:</p>
                        </Col>
                        <Col className="col-sm-3">
                            <DatePicker
                                selected={this.state.startDate}
                                onChange={this.handleChangeStartDate}
                                name="startDate"
                                dateFormat="yyyy.MM.dd"
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <p>End date:</p>
                        </Col>
                        <Col className="col-sm-3">
                            <DatePicker
                                selected={this.state.endDate}
                                onChange={this.handleChangeEndDate}
                                name="endDate"
                                dateFormat="yyyy.MM.dd"
                            />
                        </Col>
                        <Col className="col-sm-2">
                            <Button type="submit" variant="primary"
                                    onClick={() => {
                                        this.loadStorageUsageHistory();
                                        this.loadDownloadUsageHistory();
                                    }}
                            >
                                Search
                            </Button>
                        </Col>
                    </Row>
                    <br/>
                    <h2>Storage usage</h2>
                    <Row>
                        <Col>
                            <p className="text-left">Average: <b>{formatFunction(this.state.storageUsage.Average)}</b>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <h2 className="chartname text-left">Storage usage over period</h2>
                        <AreaChart
                            width={700}
                            height={300}
                            data={storageChartData}
                            margin={{
                                top: 30,
                                right: 30,
                                left: 0,
                                bottom: 0
                            }}
                        > <Tooltip
                            wrapperStyle={{backgroundColor: "red"}}
                            labelStyle={{color: "#115CA6"}}
                            itemStyle={{color: "#003468"}}
                            formatter={usageToolTipFormatter}
                        />
                            <CartesianGrid strokeDasharray="1 1"/>
                            <XAxis dataKey="name"
                                   fontFamily="sans-serif"
                                   scale="date"
                                   tickFormatter={dateFormatter}
                                   dy={5}
                                   minTickGap={20}
                                   tickLine={true}
                                   fontSize={12}
                                   tick={{fill: "#003468"}}

                            />
                            <YAxis tickFormatter={formatFunction}
                                   fontSize={12}
                                   tick={{fill: "#003468"}}
                                   minTickGap={20}
                                   width={80}

                            />
                            <Tooltip/>
                            <defs>
                                <linearGradient id="colorUsage" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#003468" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#003468" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <Area type="monotone" dataKey="usage" stroke="#003468" fillOpacity={1}
                                  fill="url(#colorUsage)"/>
                        </AreaChart>
                    </Row>
                </Container>

                <p>&nbsp;</p>
                <h2>Download usage</h2>
                <Container>
                    <Row>
                        <Col>
                            <p className="text-left">Average: <b>{formatFunction(this.state.downloadUsage.Average)}</b>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <h2 className="chartname text-left">Download usage over period</h2>
                        <AreaChart
                            width={700}
                            height={300}
                            data={downloadChartData}
                            margin={{
                                top: 30,
                                right: 30,
                                left: 0,
                                bottom: 0
                            }}
                        > <Tooltip
                            wrapperStyle={{backgroundColor: "red"}}
                            labelStyle={{color: "#115CA6"}}
                            itemStyle={{color: "#003468"}}
                            formatter={downloadedToolTipFormatter}
                        />
                            <CartesianGrid strokeDasharray="1 1"/>
                            <XAxis dataKey="name"
                                   fontFamily="sans-serif"
                                   scale="date"
                                   tickFormatter={dateFormatter}
                                   dy={5}
                                   minTickGap={20}
                                   tickLine={true}
                                   fontSize={12}
                                   tick={{fill: "#003468"}}

                            />
                            <YAxis tickFormatter={formatFunction}
                                   fontSize={12}
                                   tick={{fill: "#003468"}}
                                   minTickGap={20}
                                   width={80}

                            />
                            <Tooltip/>
                            <defs>
                                <linearGradient id="colorUsage" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#003468" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#003468" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <Area type="monotone" dataKey="usage" stroke="#003468" fillOpacity={1}
                                  fill="url(#colorUsage)"/>
                        </AreaChart>
                    </Row>
                </Container>
                </>
                }
            </div>
        </Layout>;
    }
}

export default Usage