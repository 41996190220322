import React from 'react';
import ReactDOM from 'react-dom';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import './index.css';
import AccountList from "./page/account/AccountList";
import AccountEdit from "./page/account/AccountEdit";
import AdminList from './page/AdminList';
import AdminEdit from './page/AdminEdit';
import SignIn from './page/SignIn';
import SignOut from './page/SignOut';
import Usage from './page/Usage'
import AccountPackage from './page/account/AccountPackage'
import InvoiceList from './page/invoice/InvoiceList'
import InvoiceView from './page/invoice/InvoiceView'
import AccountInvoiceList from './page/account/AccountInvoiceList'

import NotFound from './page/NotFound';

import * as serviceWorker from './serviceWorker';
import Overview from "./page/Overview";
import InvoicePayment from "./page/invoice/InvoicePayment";
import InvoicePaymentMake from "./page/invoice/InvoicePaymentMake";
import AccountPartner from "./page/account/AccountPartner";
import AccountPartnerSettings from "./page/account/AccountPartnerSettings";
import EventLogList from "./page/EventLogList";
import StorageOverview from "./page/storage/StorageOverview";
import NodeList from "./page/storage/NodeList";
import DriveList from "./page/storage/DriveList";
import ContainerList from "./page/storage/ContainerList";
import ContainerView from "./page/storage/ContainerView";
import NodeView from "./page/storage/NodeView";
import DriveView from "./page/storage/DriveView";
import ContainerNew from "./page/storage/ContainerNew";
import ContainerEdit from "./page/storage/ContainerEdit";
import AccountStatistics from "./page/account/AccountStatistics";
import VoucherList from "./page/voucher/VoucherList";
import VoucherNew from "./page/voucher/VoucherNew";
import VoucherEdit from "./page/voucher/VoucherEdit";
import VoucherView from "./page/voucher/VoucherView";
import PartnerList from "./page/partner/PartnerList";
import PartnerEdit from "./page/partner/PartnerEdit";
import PartnerView from "./page/partner/PartnerView";
import PartnerApi from "./page/partner/PartnerApi";
import LocationList from "./page/location/LocationList";
import LocationView from "./page/location/LocationView";
import LocationNew from "./page/location/LocationNew";
import LocationEdit from "./page/location/LocationEdit";
import LocationEditKey from "./page/location/LocationEditKey";
import PartnerUserList from "./page/partner/PartnerUserList";
import PartnerUserView from "./page/partner/PartnerUserView";
import PartnerUserEdit from "./page/partner/PartnerUserEdit";
import AccountClosure from "./page/account/AccountClosure";
import TaskList from "./page/storage/TaskList";

const routing = (
    <Router>
        <Switch>
            <Route exact path="/" component={Overview}/>
            <Route exact path="/signIn" component={SignIn}/>
            <Route exact path="/signOut" component={SignOut}/>
            <Route exact path="/account" component={AccountList}/>
            <Route exact path="/location" component={LocationList}/>
            <Route exact path="/location/new" component={LocationNew}/>
            <Route exact path="/location/:id/edit" component={LocationEdit}/>
            <Route exact path="/location/:id/edit/key" component={LocationEditKey}/>
            <Route exact path="/location/:id/view" component={LocationView}/>
            <Route exact path="/invoice" component={InvoiceList}/>
            <Route exact path="/invoice/:id/view" component={InvoiceView}/>
            <Route exact path="/invoice/:id/payment" component={InvoicePayment}/>
            <Route exact path="/invoice/:id/paymentMake" component={InvoicePaymentMake}/>
            <Route exact path="/account/:id" component={AccountEdit}/>
            <Route exact path="/account/:id/usage" component={Usage}/>
            <Route exact path="/account/:id/statistics" component={AccountStatistics}/>
            <Route exact path="/account/:id/package" component={AccountPackage}/>
            <Route exact path="/account/:id/partner" component={AccountPartner}/>
            <Route exact path="/account/:id/partnerSettings" component={AccountPartnerSettings}/>
            <Route exact path="/account/:id/invoice" component={AccountInvoiceList}/>
            <Route exact path="/account/:id/closure" component={AccountClosure}/>
            <Route exact path="/voucher" component={VoucherList}/>
            <Route exact path="/voucher/new" component={VoucherNew}/>
            <Route exact path="/voucher/:id/edit" component={VoucherEdit}/>
            <Route exact path="/voucher/:id/view" component={VoucherView}/>
            <Route exact path="/admin" component={AdminList}/>
            <Route exact path="/admin/:id" component={AdminEdit}/>
            <Route exact path="/eventLog" component={EventLogList}/>
            <Route exact path="/storage" component={StorageOverview}/>
            <Route exact path="/storage/node" component={NodeList}/>
            <Route exact path="/storage/node/:id/view" component={NodeView}/>
            <Route exact path="/storage/drive" component={DriveList}/>
            <Route exact path="/storage/drive/:id/view" component={DriveView}/>
            <Route exact path="/storage/container" component={ContainerList}/>
            <Route exact path="/storage/container/new" component={ContainerNew}/>
            <Route exact path="/storage/container/:id/view" component={ContainerView}/>
            <Route exact path="/storage/container/:id/edit" component={ContainerEdit}/>
            <Route exact path="/storage/task" component={TaskList}/>
            <Route exact path="/partner" component={PartnerList}/>
            <Route exact path="/partner/:id/view" component={PartnerView}/>
            <Route exact path="/partner/:id/edit" component={PartnerEdit}/>
            <Route exact path="/partner/:id/api" component={PartnerApi}/>
            <Route exact path="/partner/:id/users" component={PartnerUserList}/>
            <Route exact path="/partner/:partnerId/user/:id/view" component={PartnerUserView}/>
            <Route exact path="/partner/:partnerId/user/:id/edit" component={PartnerUserEdit}/>

            <Route component={NotFound}/>
        </Switch>
    </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
