import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {get, put, del} from '../../utils/request'
import Layout from "../../component/Layout";
import StorageNav from "../../component/StorageNav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Spinner} from "react-bootstrap";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class ContainerEdit extends React.Component {
    constructor(props) {
        super(props);

        const query = new URLSearchParams(window.location.search);
        const location = query.get('location');

        this.state = {
            id: this.props.match.params.id,
            data: {
                KeepEmpty: false,
                CanBeDeleted: false,
            },
            editSuccessMessage: '',
            editErrorMessage: '',
            deleteSuccessMessage: '',
            deleteErrorMessage: '',
            loaded: false,
            location: location,
        };

        this.loadData = this.loadData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
    }

    async loadData() {
        if(!this.state.location) {
            return;
        }

        const [res, err] = await get(this, "/storage/container/" + this.state.id + "/in/" + this.state.location);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }

        this.setState({data: res.data});
        this.setState({errorMessage: ""});
        this.setState({loaded: true});
    }

    async componentDidMount() {
        await this.loadData();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const data = this.state.data;

        if (target.type === 'checkbox') {
            data[name] = event.target.checked;
        } else {
            data[name] = value;
        }
        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({editErrorMessage: ""});


        const [res, err] = await put(this, "/storage/container/" + this.state.id + "/in/" + this.state.location, this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({editErrorMessage: "Validation error: " + err.response.message});
                        return;
                    case 500:
                        this.setState({editErrorMessage: "Internal error: " + err.response.message});
                        return;
                }
            }
            this.setState({editErrorMessage: err});
            return;
        }
        this.setState({editSuccessMessage: "Container updated!"});
        await this.loadData();
    }

    async handleDeleteClick(event) {
        event.preventDefault();

        if (window.confirm('Are you sure you wish to delete this container?')) {
            this.setState({deleteErrorMessage: ""});
            const [, err] = await del(this, "/storage/container/" + this.state.id + "/in/" + this.state.location);
            if (err) {
                if (err && err.response && err.response.status) {
                    switch (err.response.status) {
                        case 400:
                            this.setState({deleteErrorMessage: "Validation error: " + err.response.message});
                            return;
                        case 500:
                            this.setState({deleteErrorMessage: "Internal error: " + err.response.message});
                            return;
                    }
                }
                this.setState({deleteErrorMessage: err});
                return;
            }
            this.setState({deleteSuccessMessage: "Container deleted!"});
            await this.loadData();
        }
    }

    render() {
        return <Layout activeMenu="storage">
            <StorageNav activeKey="container"/>
            {!this.state.loaded &&
                <>
                    <Spinner animation="border" variant="primary"/>Data not loaded...
                    <br/><br/>
                </>
            }
            {this.state.loaded && <>
            <div style={divStyle}>
                <h2>Edit container {this.state.id}</h2>
                {this.state.editSuccessMessage && <Alert variant="success">{this.state.editSuccessMessage}</Alert>}
                {this.state.editErrorMessage && <Alert variant="danger">{this.state.editErrorMessage}</Alert>}
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    <Form.Group as={Row} controlId="formPlaintextName">
                        <Form.Label column sm="3">KeepEmpty</Form.Label>
                        <Col sm="9"><input type="checkbox" name="KeepEmpty" checked={this.state.data.KeepEmpty}
                                           onChange={this.handleChange}/></Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">Save</Button>
                </Form>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <h2>Delete container {this.state.id}</h2>
                {this.state.deleteSuccessMessage && <Alert variant="success">{this.state.deleteSuccessMessage}</Alert>}
                {this.state.deleteErrorMessage && <Alert variant="danger">{this.state.deleteErrorMessage}</Alert>}
                {!this.state.data.CanBeDeleted &&
                <div>This container can <strong>NOT</strong> be deleted right now. Container needs to be set to
                    KeepEmpty and must be empty of files to be deletable.</div>}
                {this.state.data.CanBeDeleted &&
                <div>
                    <div>This container can be deleted</div>
                    <div><Button variant="outline-primary" onClick={e => this.handleDeleteClick(e)}>Delete container</Button></div>
                </div>
                }
            </div>
            </>
            }
        </Layout>;
    }
}

export default ContainerEdit