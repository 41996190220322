import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { put } from '../../utils/request';
import Layout from "../../component/Layout";
import LocationNav from "../../component/LocationNav";

const divStyle = {
    margin: '40px auto',
    width: '500px'
};

class LocationEditKey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            data: {},
            successMessage: '',
            errorMessage: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.data;
        data[name] = value;

        this.setState({data: data});
    }

    async handleSubmit(event) {
        event.preventDefault();

        this.setState({errorMessage: ""});

        const [res, err] = await put(this, "/location/" + this.state.id + "/key", this.state.data);
        if (err) {
            if (err && err.response && err.response.status) {
                switch (err.response.status) {
                    case 400:
                        this.setState({errorMessage: "Error: Bad request"});
                        return;
                    case 404:
                        this.setState({errorMessage: "Error: Location not found"});
                        return;
                    case 409:
                        this.setState({errorMessage: "Error: Location already exists"});
                        return;
                    case 412:
                        this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        return;
                    case 422:
                        if (err.response.data !== null) {
                            this.setState({errorMessage: "Validation error: " + JSON.stringify(err.response.data.ValidationErrors)});
                        } else {
                            this.setState({errorMessage: "Validation error"});
                        }
                        return;
                    case 500:
                        this.setState({errorMessage: "Internal error: " + err.response.message});
                        return;
                    case 501:
                        this.setState({errorMessage: "Location not implemented"});
                        return;
                }
            }
            this.setState({errorMessage: "Error:", err});
            return;
        }
        this.props.history.push('/location/' + this.state.id + '/view')
    }

    render() {
        const { data, errorMessage, id, successMessage } = this.state;

        if (!id) {
            return <div>Loading data...</div>
        }
        return <Layout activeMenu="location">
            <LocationNav activeKey="key" id={id}/>
            <div style={divStyle}>
                <h1>Location key edit</h1>
                <Form className="form-fieldnames" onSubmit={e => this.handleSubmit(e)}>
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Form.Group as={Row} controlId="formDecryptedCert">
                        <Form.Label column sm="4">Cert (PEM)</Form.Label>
                        <Col sm="8">
                            <Form.Control type="text"
                                          as="textarea"
                                          name="decryptedCert"
                                          defaultValue={data.decryptedCert}
                                          rows="3"
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formDecryptedKey">
                        <Form.Label column sm="4">Key (PEM)</Form.Label>
                        <Col sm="8">
                            <Form.Control type="text"
                                          as="textarea"
                                          name="decryptedKey"
                                          defaultValue={data.decryptedKey}
                                          rows="3"
                                          onChange={this.handleChange}/>
                        </Col>
                    </Form.Group>
                    <Button type="submit" variant="primary">Update key</Button>
                </Form>
            </div>
        </Layout>;
    }
}

export default LocationEditKey