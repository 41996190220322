import React from 'react'
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import {get, post} from '../../utils/request'
import Table from "react-bootstrap/Table";
import Layout from "../../component/Layout";
import {CountryByCode} from "../../component/CountryByCode";
import {Col, Form, Row} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {unHumanize} from "../../utils/humanReadableSize";
import CountrySelector from "../../component/CountrySelector";

const filesize = require('filesize');
const dayjs = require('dayjs');

const dateFormat = 'YYYY-MM-DD';
const requestDateFormat = 'YYYY-MM-DD';
const timeFormat = 'YYYY-MM-DD HH:mm';

class AccountList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accounts: [],
            errorMessage: "",
            partners: [],
            filter: {
                name: "",
                email: "",
                company: "",
                status: "all_but_closed",
                signUpSource: "",
                paid: "",
                createdStart: new Date(dayjs().subtract(1, 'months').format(dateFormat)),
                createdEnd: new Date(),
                usageMin: "",
                usageMax: "",
                partnerChoice: "",
                partnerId: "",
            },
            filterHelper: {
                createdStartEnabled: false,
                createdEndEnabled: false,
            }
        };
        this.loadData = this.loadData.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.loadPartners = this.loadPartners.bind(this);
        this.filterChange = this.filterChange.bind(this);
        this.partnerFilterChange = this.partnerFilterChange.bind(this);
        this.filterHelperChange = this.filterHelperChange.bind(this);
        this.createdStartChange = this.createdStartChange.bind(this);
        this.createdEndChange = this.createdEndChange.bind(this);
        this.countryCodeChange = this.countryCodeChange.bind(this);
    }

    async loadData(filter) {
        const postData = {
            Filter: filter,
            FilterOptions: {}
        }
        const [res, err] = await post(this, "/account/search", postData);
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({accounts: res.data});
    }

    async loadPartners() {
        const [res, err] = await get(this, "/partner");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            return
        }
        this.setState({partners: res.data});
    }

    async componentDidMount() {
        await this.loadPartners();
        await this.doSearch();
    }

    yesNoBoolean(filter, field) {
        if (!filter[field]) {
            delete filter[field];
            return
        }
        filter[field] = filter[field] === "Yes";
    }

    onlyIfEnabled(filter, field, condition) {
        if (!condition) {
            delete filter[field]
        }
    }

    ifBytes(filter, field) {
        const value = filter[field];
        if (!value) {
            delete filter[field];
            return true;
        }
        filter[field] = Number(unHumanize(value));
    }

    async handleSearch(event) {
        event.preventDefault();
        await this.doSearch();
    }

    async doSearch() {
        const filter = {...this.state.filter}; // we copy object because we edit the values

        this.yesNoBoolean(filter, "paid");

        this.onlyIfEnabled(filter, "createdStart", this.state.filterHelper.createdStartEnabled)
        this.onlyIfEnabled(filter, "createdEnd", this.state.filterHelper.createdEndEnabled)

        this.ifBytes(filter, "usageMin");
        this.ifBytes(filter, "usageMax");

        await this.loadData(filter);
    }

    filterChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.filter;
        data[name] = value;

        this.setState({filter: data});
    }

    partnerFilterChange(event) {
        const target = event.target;
        const value = target.value;
        const data = this.state.filter;

        if (value === "anyPartnerId" || value === "noPartnerId") {
            data["partnerChoice"] = value
            data["partnerId"] = ""
        } else if (value !== "") {
            data["partnerChoice"] = "byPartnerId"
            data["partnerId"] = value
        } else {
            data["partnerChoice"] = ""
            data["partnerId"] = ""
        }

        this.setState({filter: data});
    }

    filterHelperChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = this.state.filterHelper;
        data[name] = value;

        this.setState({filterHelper: data});
    }

    createdStartChange(newDate) {
        const data = this.state.filter;
        data.CreatedStart = newDate;
        this.setState({filter: data});
    }

    createdEndChange(newDate) {
        const data = this.state.filter;
        data.CreatedEnd = newDate;
        this.setState({filter: data});
    }

    countryCodeChange(newValue) {
        const data = this.state.filter;
        data.CountryCode = newValue;
        this.setState({filter: data});
    }

    render() {
        let usageTotal = 0;
        this.state.accounts.forEach(account => {
            usageTotal += account.usage;
        })

        return <Layout activeMenu="account">
            <h1>Accounts ({this.state.accounts ? this.state.accounts.length : 0})</h1>
            <br/>
            {this.state.errorMessage && <Alert variant="danger">{this.state.errorMessage}</Alert>}
            <Form className="form-fieldnames" onSubmit={e => this.handleSearch(e)}>
                <Row>
                    <Col sm>
                        <Form.Group controlId="formName">
                            <Form.Label column>Name:</Form.Label>
                            <Form.Control type="text" name="name" defaultValue={this.state.filter.name}
                                          onChange={this.filterChange}/>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formEmail">
                            <Form.Label column>Email:</Form.Label>
                            <Form.Control type="text" name="email" defaultValue={this.state.filter.email}
                                          onChange={this.filterChange}/>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formCompany">
                            <Form.Label column>Company:</Form.Label>
                            <Form.Control type="text" name="company" defaultValue={this.state.filter.company}
                                          onChange={this.filterChange}/>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formPaid">
                            <Form.Label column>Paid:</Form.Label>
                            <Form.Control as="select" name="paid" defaultValue={this.state.filter.paid}
                                          onChange={this.filterChange}>
                                <option value="">All</option>
                                <option>Yes</option>
                                <option>No</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formStatus">
                            <Form.Label column>Status:</Form.Label>
                            <Form.Control as="select" name="status" defaultValue={this.state.filter.status}
                                          onChange={this.filterChange}>
                                <option value="">All</option>
                                <option value="all_but_closed">All but closed</option>
                                <option>active</option>
                                <option>waiting_confirm</option>
                                <option>closed</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formSignupSource">
                            <Form.Label column>Signup source:</Form.Label>
                            <Form.Control as="select" name="signupSource" defaultValue={this.state.filter.signupSource}
                                          onChange={this.filterChange}>
                                <option value="">All</option>
                                <option value="google">Google click</option>
                                <option value="fb">FB click</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formPartner">
                            <Form.Label column>Partner:</Form.Label>
                            <Form.Control as="select" name="partner" defaultValue={this.state.filter.partner}
                                          onChange={this.partnerFilterChange}>
                                <option value=""></option>
                                <option value="anyPartnerId">-- with partner --</option>
                                <option value="noPartnerId">-- without partner --</option>
                                {this.state.partners.map((value, index) => {
                                    return <option value={value.partnerId}>{value.name}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Button type="submit" variant="primary">Search</Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm>
                        <Form.Group controlId="formCreatedStart">{this.state.filterHelper.createdStartEnabled}
                            <Form.Label column>Created start:</Form.Label>
                            <Form.Check type="checkbox" name="createdStartEnabled"
                                        defaultChecked={this.state.filterHelper.createdStartEnabled}
                                        onChange={this.filterHelperChange}/>
                            {this.state.filterHelper.createdStartEnabled &&
                                <DatePicker
                                    selected={this.state.filter.createdStart}
                                    onChange={this.createdStartChange}
                                    name="createdStart"
                                    dateFormat="yyyy.MM.dd"
                                />
                            }
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="formCreatedEnd">
                            <Form.Label column>Created end:</Form.Label>
                            <Form.Check type="checkbox" name="createdEndEnabled"
                                        defaultChecked={this.state.filterHelper.createdEndEnabled}
                                        onChange={this.filterHelperChange}/>
                            {this.state.filterHelper.createdEndEnabled &&
                                <DatePicker
                                    selected={new Date(this.state.filter.createdEnd)}
                                    onChange={this.createdEndChange}
                                    name="createdEnd"
                                    dateFormat="yyyy.MM.dd"
                                />
                            }
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="countryCode">
                            <Form.Label column>Country:</Form.Label>
                            <CountrySelector
                                allowAll={true}
                                selected={this.state.filter.countryCode}
                                onChange={this.countryCodeChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="usageMin">
                            <Form.Label column>Usage min.:</Form.Label>
                            <Form.Control type="text" name="usageMin" defaultValue={this.state.filter.usageMin}
                                          onChange={this.filterChange}/>
                        </Form.Group>
                    </Col>
                    <Col sm>
                        <Form.Group controlId="usageMax">
                            <Form.Label column>Usage max.:</Form.Label>
                            <Form.Control type="text" name="usageMax" defaultValue={this.state.filter.usageMax}
                                          onChange={this.filterChange}/>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <Table bordered hover>
                <thead className="thead-light">
                <tr>
                    <th>Nr.</th>
                    <th>Name</th>
                    <th>Partner / company / country</th>
                    <th>Email</th>
                    <th>Created</th>
                    <th>Paid</th>
                    <th>Usage ({filesize(usageTotal)})</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {this.state.accounts && this.state.accounts.map((value, index) => {
                    let usagePercentage = Math.round(value.usage / value.usageLimit * 100);
                    if (Number.isNaN(usagePercentage)) {
                        usagePercentage = 0;
                    }

                    const hasHadUsage = (value.hasHadUsage && !value.usage) ? <div>but has used</div> : "";

                    let paid = <span className="badge badge-pill badge-warning">No</span>;
                    if (value.paid === true) {
                        paid = <span className="badge badge-pill badge-success">Yes</span>;
                    }

                    let status = <span className="badge badge-pill badge-warning">{value.status}</span>;
                    if (value.status === "active") {
                        status = <span className="badge badge-pill badge-success">{value.status}</span>;
                    } else if (value.status === "closed") {
                        status = <span className="badge badge-pill badge-danger">{value.status}</span>;
                    }

                    let partner = "";
                    if (value.partnerId) {
                        for (let i = 0; i < this.state.partners.length; i++) {
                            if (this.state.partners[i].partnerId === value.partnerId) {
                                partner = <span
                                    className="badge badge-pill badge-success">{this.state.partners[i].name}</span>;
                                break;
                            }
                        }
                    }

                    return <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{value.name}</td>
                        <td>{partner} {value.company} / {CountryByCode(value.countryCode, "")}</td>
                        <td>{value.email}</td>
                        <td>{dayjs(value.Created).format(timeFormat)}</td>
                        <td>{paid}</td>
                        <td>
                            {filesize(value.usage)} ({usagePercentage}% of {filesize(value.usageLimit)})
                            {hasHadUsage}
                        </td>
                        <td>{status}</td>
                        <td><Button variant="outline-primary" href={"/account/" + value.id}>Edit</Button></td>
                    </tr>
                })}
                </tbody>
            </Table>
        </Layout>;
    }
}

export default AccountList